<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <rect
      class="a"
      width="24"
      height="24"
    />

    <path
      class="b"
      d="M653-520a8.009,8.009,0,0,1,8-8,8.009,8.009,0,0,1,8,8,8.009,8.009,0,0,1-8,8,8.009,8.009,0,0,1-8-8Zm1,0a7.008,7.008,0,0,0,7,7,7.008,7.008,0,0,0,7-7,7.008,7.008,0,0,0-7-7A7.008,7.008,0,0,0,654-520Zm10.147,4.187-3.5-3.5a.5.5,0,0,1-.147-.353v-4.667a.5.5,0,0,1,.5-.5.5.5,0,0,1,.5.5v4.459l3.353,3.353a.5.5,0,0,1,0,.707.5.5,0,0,1-.353.147.5.5,0,0,1-.353-.147Z"
      transform="translate(-649 532)"
    />
  </svg>
</template>

<script>
export default {
  name: 'Wait',
};
</script>

<style lang="scss" scoped>
.a {
  fill:none;
}

.b {
  fill: currentColor;
  stroke: currentColor;
  stroke-width:0.3px;
}
</style>
